import { Box, Button, IconButton, FormLabel } from '@mui/joy';
import { Input } from '@esui/lib.formik.input';
import { Textarea } from '@esui/lib.formik.textarea';
import toast from 'react-hot-toast';
import { FieldArray } from 'formik';
import { Add, Delete } from '@mui/icons-material';

import { useParams } from 'react-router-dom';

import TripEditLayout from '../TripEditLayout';
import ExpandableContent from '../ExpandableContent';
import { usePatchTrip } from '../../../lib/trip';
import Markdown from '../../../components/Markdown/Markdown';
import { ImageEliminator, ImageReplacer } from '../../../components/ImageUploader/ImageUploader';
import LocaleSelector from '../../../components/LandingPage/LocaleSelector';
import TranslatedFieldLabel from '../../../components/TranslatedFieldLabel/TranslatedFieldLabel';

import { useLocale } from '../../../hooks/useLocale';

import { repathImg, REPOSITORIES } from '../../../lib/upload';

import NewPicture from './NewPicture';

export default function Content() {
  const [locale] = useLocale();
  const { tripId } = useParams();

  const handleMutation = async (_id, newTrip) => {
    const newData = {
      photos: newTrip.photos,
      cover_url: newTrip.cover_url,
      i18n: newTrip.i18n,
      title: newTrip.i18n?.en_EN?.title || newTrip.title
    };

    await toast.promise(
      usePatchTrip({
        _id,
        ...newData
      }),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  if (!locale) return null;

  return (
    <TripEditLayout title="Content" handleMutation={handleMutation}>
      {(values, setFieldValue) => (
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: 'auto' }}>
            <LocaleSelector />
            <ExpandableContent title="Main content">
              <Box>
                <FormLabel>Header</FormLabel>
                <ImageReplacer
                  url={repathImg(values.cover_url, REPOSITORIES.TRIP_COVER, { _id: tripId })}
                  handleMutation={([url]) => setFieldValue('cover_url', url)}
                  repository={REPOSITORIES.TRIP_COVER}
                />
              </Box>
              <Box>
                <Textarea
                  label={<TranslatedFieldLabel label="Title" />}
                  name={`i18n.${locale}.title`}
                />
              </Box>
              <Box>
                <Textarea
                  label={<TranslatedFieldLabel label="Excerpt" />}
                  name={`i18n.${locale}.excerpt`}
                />
              </Box>
              <Box>
                <FormLabel>
                  <TranslatedFieldLabel label="Content" />
                </FormLabel>

                <Markdown
                  defaultContent={values.i18n[locale]?.content || ''}
                  handleOnChange={(markdown) => setFieldValue(`i18n.${locale}.content`, markdown)}
                />
              </Box>
            </ExpandableContent>

            <ExpandableContent title="Pictures">
              <FieldArray name="photos">
                {({ push, remove }) => (
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                      gridGap: '20px'
                    }}>
                    {values.photos
                      .map((photo) => ({
                        ...photo,
                        url: repathImg(photo.url, REPOSITORIES.TRIP, { _id: tripId })
                      }))
                      .map((photo, index) => (
                        <ImageEliminator
                          key={photo.url}
                          url={photo.url}
                          index={index}
                          handleDelete={() => {
                            remove(index);
                          }}
                        />
                      ))}
                    <NewPicture
                      repository={REPOSITORIES.TRIP}
                      handleNewPicture={(urls) => {
                        urls.forEach((url) => push({ url, alt: '' }));
                      }}
                    />
                  </Box>
                )}
              </FieldArray>
            </ExpandableContent>

            <ExpandableContent multiLocale title="Highlights">
              <FieldArray name={`i18n.${locale}.highlights`}>
                {({ push, remove }) => {
                  const highlights = values.i18n[locale]?.highlights;
                  return (
                    <>
                      {(highlights || []).map((data, index) => (
                        <Input
                          name={`i18n.${locale}.highlights.${index}`}
                          type="text"
                          endDecorator={
                            <IconButton size="sm" onClick={() => remove(index)}>
                              <Delete />
                            </IconButton>
                          }
                        />
                      ))}
                      <IconButton
                        size="sm"
                        sx={{ width: 'fit-content', marginTop: '10px', margin: 'auto' }}
                        color="primary"
                        variant="outlined"
                        onClick={() => push('')}>
                        <Add />
                      </IconButton>
                    </>
                  );
                }}
              </FieldArray>
            </ExpandableContent>

            <ExpandableContent multiLocale title="Itinerary">
              <FieldArray name={`i18n.${locale}.itinerary`}>
                {({ push, remove }) => {
                  const itinerary = values.i18n[locale]?.itinerary || [];

                  return (
                    <>
                      {itinerary.map((data, index) => (
                        <ExpandableContent
                          key={data._id}
                          smallTitle={
                            data.title
                              ? `${data.title} - ${data.days.join(' -> ')}`
                              : 'Untitled day'
                          }>
                          <Box
                            sx={{
                              margin: '20px 0px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '5px',
                              background: '#fafafa',
                              padding: '20px'
                            }}>
                            <FieldArray name={`i18n.${locale}.itinerary.${index}.days`}>
                              {(dayActions) => (
                                <Box
                                  sx={{
                                    border: '1px solid #ebebeb',
                                    padding: '10px',
                                    borderRadius: '4px'
                                  }}>
                                  {data.days.map((day, i) => (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                      <Box>
                                        <Input
                                          label="Day"
                                          name={`i18n.${locale}.itinerary.${index}.days.${i}`}
                                          type="number"
                                        />
                                      </Box>
                                      <IconButton size="sm" onClick={() => dayActions.remove(i)}>
                                        <Delete />
                                      </IconButton>
                                    </Box>
                                  ))}
                                  <Button
                                    variant="outlined"
                                    size="sm"
                                    sx={{ marginTop: '10px' }}
                                    onClick={() => dayActions.push('')}>
                                    Add day
                                  </Button>
                                </Box>
                              )}
                            </FieldArray>
                            <Input label="Title" name={`i18n.${locale}.itinerary.${index}.title`} />

                            <Box>
                              <FormLabel sx={{ marginTop: '10px' }}>
                                <TranslatedFieldLabel label="Day/s description" />
                              </FormLabel>

                              <Markdown
                                defaultContent={
                                  values.i18n[locale].itinerary[index].description || ''
                                }
                                handleOnChange={(markdown) =>
                                  setFieldValue(
                                    `i18n.${locale}.itinerary.${index}.description`,
                                    markdown
                                  )
                                }
                              />
                            </Box>
                          </Box>
                          <Button
                            color="danger"
                            variant="outlined"
                            sx={{ width: 'fit-content', margin: 'auto' }}
                            onClick={() => remove(index)}>
                            Remove item
                          </Button>
                        </ExpandableContent>
                      ))}
                      <IconButton
                        size="sm"
                        sx={{ width: 'fit-content', marginTop: '10px', margin: 'auto' }}
                        color="primary"
                        variant="outlined"
                        onClick={() => push({ days: [], title: '', description: '' })}>
                        <Add />
                      </IconButton>
                    </>
                  );
                }}
              </FieldArray>
            </ExpandableContent>

            <ExpandableContent title="Other information">
              <Textarea
                minRows={3}
                maxRows={15}
                label={<TranslatedFieldLabel label="Accommodation" />}
                name={`i18n.${locale}.accommodation`}
              />

              <Box>
                <TranslatedFieldLabel label="Extra information" />

                <Markdown
                  defaultContent={values.i18n[locale]?.extra_information || ''}
                  handleOnChange={(markdown) =>
                    setFieldValue(`i18n.${locale}.extra_information`, markdown)
                  }
                />
              </Box>

              <Textarea
                minRows={3}
                maxRows={15}
                label={<TranslatedFieldLabel label="Cancellation Policies" />}
                name={`i18n.${locale}.cancellation_policies`}
              />
              <Textarea
                minRows={3}
                maxRows={15}
                label={<TranslatedFieldLabel label="Disclaimer" />}
                name={`i18n.${locale}.disclaimer`}
              />
              <Textarea
                minRows={2}
                maxRows={5}
                label="URL of the source content"
                name="source_content_url"
              />
            </ExpandableContent>
          </Box>
        </Box>
      )}
    </TripEditLayout>
  );
}
