import { Box, Button, Table, Typography, Chip } from '@mui/joy';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import MainLayout from '../Layouts/MainLayout';

import DeleteReview from './DeleteReview';
import EditReview from './EditReview';

export default function Reviews({ reviews, addReviewComponent }) {
  const { tripId, guideId } = useParams();
  const [reviewToEdit, setReviewToEdit] = useState(null);
  const [reviewToDelete, setReviewToDelete] = useState(null);

  const backPathname = tripId ? `/trip/${tripId}/options` : `/guide/${guideId}/`;

  if (!reviews.length) {
    return (
      <MainLayout title="Reviews" options={addReviewComponent} back={backPathname}>
        <Typography>No reviews yet</Typography>
      </MainLayout>
    );
  }

  return (
    <>
      <MainLayout title="Reviews" options={addReviewComponent} back={backPathname}>
        <Table borderAxis="bothBetween">
          <thead>
            <tr>
              <th style={{ width: 70 }}>Lead</th>

              <th style={{ width: '100%' }}>Guide rating</th>
              <th style={{ width: '100%' }}>Trip rating</th>
              <th style={{ width: '100%' }}>Explore-Share rating</th>
              <th style={{ width: 70 }}>Date</th>
              <th aria-label="last" style={{ width: 120 }} />
            </tr>
          </thead>
          <tbody>
            {reviews.map((review) => (
              <>
                <tr key={review._id}>
                  <td>
                    {review.fake_data ? (
                      <>
                        {review.fake_data.lead}
                        {review.fake_data.source && <Chip>{review.fake_data.source}</Chip>}
                      </>
                    ) : (
                      <>
                        {review.lead?.first_name} {review.lead?.last_name}
                      </>
                    )}
                  </td>

                  <td>{review.data.guide} </td>
                  <td>{review.data.trip} </td>
                  <td>{review.data.us} </td>
                  <td>{dayjs.utc(review.created).format('DD/MM/YYYY')}</td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        size="sm"
                        variant="plain"
                        color="neutral"
                        onClick={() => setReviewToEdit(review)}
                      >
                        Edit
                      </Button>
                      <Button
                        size="sm"
                        variant="soft"
                        color="danger"
                        onClick={() => setReviewToDelete(review)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </td>
                </tr>

                {review.data.comments && review.data.comments !== '' ? (
                  <tr style={{ background: '#e8e8e8' }}>
                    <td colSpan="7">
                      <Typography level="body-xs"> Comments: {review.data.comments}</Typography>
                    </td>
                  </tr>
                ) : null}

                {review.data.comment ? (
                  <tr style={{ background: '#e8e8e8' }}>
                    <td colSpan="7">
                      <Typography level="body-xs">
                        Trip Guide Comment: {review.data.comment}
                      </Typography>
                    </td>
                  </tr>
                ) : null}

                {review.data.us_comment ? (
                  <tr style={{ background: '#e8e8e8' }}>
                    <td colSpan="7">
                      <Typography level="body-xs">Us Comment: {review.data.us_comment}</Typography>
                    </td>
                  </tr>
                ) : null}

                {review.data.images ? (
                  <tr style={{ background: '#e8e8e8' }}>
                    <td colSpan="7">
                      {review.data.images?.map((img) => {
                        return (
                          <a
                            href={`https://assets.explore-share.com/${img}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={`https://assets.explore-share.com/${img}?width=60`} />
                          </a>
                        );
                      })}
                    </td>
                  </tr>
                ) : null}
              </>
            ))}
          </tbody>
        </Table>
      </MainLayout>
      <EditReview reviewToEdit={reviewToEdit} setReviewToEdit={setReviewToEdit} />
      <DeleteReview reviewToDelete={reviewToDelete} setReviewToDelete={setReviewToDelete} />
    </>
  );
}
