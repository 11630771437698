import { Datepicker } from '@esui/lib.formik.datepicker';
import { CalendarMonth } from '@mui/icons-material';
import { Box, IconButton } from '@mui/joy';
import { Form, Formik } from 'formik';

import ButtonLoading from '../../components/ButtonLoading';

import MainLayout from '../../components/Layouts/MainLayout';
import { downloadReviewsReport } from '../../lib/admin';

export default function Reviews() {
  return (
    <Formik
      initialValues={{
        startDate: new Date(),
        endDate: new Date()
      }}
      onSubmit={async (values) => {
        const csvString = await downloadReviewsReport(values);
        const blob = new Blob([csvString], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        a.download = 'reviews.csv';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }}
    >
      <Form>
        <MainLayout
          title="Download reviews report"
          options={
            <ButtonLoading size="sm" type="submit" isLoading={false}>
              Download
            </ButtonLoading>
          }
        >
          <Box sx={{ height: '100dvh' }}>
            <Box>
              <Datepicker
                name="startDate"
                label="Start date"
                locale="es-ES"
                autoComplete="off"
                endDecorator={
                  <IconButton>
                    <CalendarMonth />
                  </IconButton>
                }
                sx={{
                  '--joy-palette-neutral-plainDisabledColor': '#414141',
                  '--joy-palette-neutral-outlinedDisabledColor': '#414141'
                }}
                calendarProps={{
                  locale: 'en-GB'
                }}
              />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <Datepicker
                name="endDate"
                label="End date"
                locale="es-ES"
                autoComplete="off"
                endDecorator={
                  <IconButton>
                    <CalendarMonth />
                  </IconButton>
                }
                sx={{
                  '--joy-palette-neutral-plainDisabledColor': '#414141',
                  '--joy-palette-neutral-outlinedDisabledColor': '#414141'
                }}
                calendarProps={{
                  locale: 'en-GB'
                }}
              />
            </Box>
          </Box>
        </MainLayout>
      </Form>
    </Formik>
  );
}
