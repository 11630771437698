import * as React from 'react';
import { Box, Typography, Card } from '@mui/joy';

import ControlButtons from '../../components/PendingApproval/ControlButtons';

export const ThankYoumessage = ({ data, tripId, field, handleConfirm, updatingState }) => {
  return (
    <Card>
      <Typography level="h5">Thank You Message</Typography>
      <Box>
        <Typography level="body-sm">
          <div
            dangerouslySetInnerHTML={{
              __html: data.replaceAll('\n', '<br />') || '<i>Thank you message removed</i>'
            }}
          />
        </Typography>
      </Box>
      <ControlButtons
        to=""
        loading={updatingState.loading}
        handleConfirm={() => handleConfirm({ tripId, field })}
      />
    </Card>
  );
};

const DefaultMessage = ({ data, tripId, field, handleConfirm, updatingState }) => {
  return (
    <Card>
      <Typography level="h5">Default Message</Typography>
      <Box>
        <Typography level="body-sm">
          <div
            dangerouslySetInnerHTML={{
              __html: data.replaceAll('\n', '<br />') || '<i>Default message removed</i>'
            }}
          />
        </Typography>
      </Box>
      <ControlButtons
        to=""
        loading={updatingState.loading}
        handleConfirm={() => handleConfirm({ tripId, field })}
      />
    </Card>
  );
};

export default DefaultMessage;
