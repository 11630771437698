import { ChangeCircle, Delete, Edit } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/joy';
import { useMemo, useState } from 'react';
import { Textarea } from '@esui/lib.formik.textarea';

import { useParams } from 'react-router-dom';

import SharedModal from '../SharedModal';
import datoCmsClient from '../../utils/datoCms';
import { REPOSITORIES, uploadImageToRepository } from '../../lib/upload';

function Progress({ value = 0, max = 100, height = 5, animated = false }) {
  const containerStyle = useMemo(() => ({ height }), [height]);
  const style = useMemo(() => {
    return {
      width: `${(value / max) * 100}%`
    };
  }, [value, max]);

  return (
    <Box
      sx={{
        ...containerStyle,
        borderRadius: '2px',
        overflow: 'hidden',
        backgroundColor: '#b3d9ff'
      }}>
      <Box
        sx={{
          ...style,
          height: '100%',
          borderRadius: '2px',
          backgroundColor: '#0a6bcb',
          transition: animated ? 'all 0.3s' : 'none'
        }}
      />
    </Box>
  );
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const ASSETS_URL = import.meta.env.VITE_ASSETS_URL;

export function useUploadImage() {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFilesAmount, setUploadingFilesAmount] = useState(0);
  const { tripId, guideId, blogPostId, id } = useParams();

  const saveFile = async (file, repository) => {
    const options = {};

    if (repository === REPOSITORIES.TRIP_COVER) {
      options.trip_id = tripId;
      options.type = 'cover';
    }

    if (repository === REPOSITORIES.TRIP) {
      options.trip_id = tripId;
      options.type = 'photos';
    }

    if (repository === REPOSITORIES.GUIDE) {
      options.guide_id = guideId;
    }

    if (repository === REPOSITORIES.LANDING_PAGE) {
      options.landing_id = id;
    }

    if (repository === REPOSITORIES.STATIC_PAGE || repository === REPOSITORIES.STATIC_PAGE_HEADER) {
      options.static_page_id = blogPostId;
    }

    setIsUploading(true);
    const resp = await uploadImageToRepository(repository, {
      images: [await toBase64(file)],
      ...options
    });

    return `${ASSETS_URL}/${resp.paths[0]}`;
  };

  const uploadImage = async (e, repository) => {
    setIsUploading(true);
    const { files } = e.target;
    const _files = [...files];

    setUploadingFilesAmount(_files.length);

    const newUrls = await Promise.all(_files.map((file) => saveFile(file, repository)));

    setIsUploading(false);
    setProgress(0);
    setUploadingFilesAmount(0);
    return newUrls;
  };

  return { uploadImage, saveFile, progress, isUploading, uploadingFilesAmount };
}

export function UploadingImage({ progress }) {
  return (
    <Box
      sx={{
        position: 'relative',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
        backdropFilter: 'blur(5px)'
      }}>
      <Box>
        <Typography sx={{ color: '#fff', fontSize: '20px', textAlign: 'center' }}>
          Uploading new image...
        </Typography>
        <Progress animated value={progress} />
      </Box>
    </Box>
  );
}

export function ImageButtonAction({ Icon }) {
  return (
    <Box
      sx={{
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        backdropFilter: 'blur(30px)',
        border: '1px solid #9e9e9e',
        alignItems: 'center',
        cursor: 'pointer',
        background: 'transparent'
      }}>
      <Icon sx={{ color: '#fff', fontSize: '30px' }} />
    </Box>
  );
}

function EditImageModal({ isEditOpen, setIsEditOpen, name }) {
  return (
    <SharedModal isOpen={isEditOpen} close={() => setIsEditOpen(false)}>
      <DialogTitle>
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Edit image metadata</Typography>
      </DialogTitle>
      <DialogContent sx={{ marginTop: '10px' }}>
        <Textarea name={name} label="Alt text" />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsEditOpen(false);
          }}>
          Confirm
        </Button>
        <Button variant="plain" color="neutral" onClick={() => setIsEditOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </SharedModal>
  );
}

export function ImageEliminator({ url, handleDelete, index }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  return (
    <>
      <Box sx={{ position: 'relative', width: 'fit-content', margin: '0px auto' }}>
        <img
          src={url}
          alt="header"
          height={300}
          style={{
            objectFit: 'cover',
            maxWidth: '100%',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)'
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            right: '10px',
            bottom: '10px',
            display: 'flex',
            gap: '10px'
          }}>
          <Box onClick={() => setIsEditOpen(true)}>
            <ImageButtonAction Icon={Edit} />
          </Box>
          <Box onClick={() => setIsOpen(true)}>
            <ImageButtonAction Icon={Delete} />
          </Box>
        </Box>
      </Box>
      <SharedModal isOpen={isOpen} close={() => setIsOpen(false)}>
        <DialogTitle>
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            Are you sure you want to delete this image?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete();
              setIsOpen(false);
            }}>
            Confirm
          </Button>
          <Button variant="plain" color="neutral" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </SharedModal>
      <EditImageModal
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        name={`photos.${index}.alt`}
      />
    </>
  );
}

export function ImageReplacer({ url, alt, handleMutation, repository }) {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { uploadImage, isUploading, progress } = useUploadImage();

  const handleReplacement = async (e) => {
    const response = await uploadImage(e, repository);
    handleMutation(response);
  };

  return (
    <Box sx={{ position: 'relative', width: 'fit-content', margin: '0px auto' }}>
      {url && !isUploading ? (
        <img
          src={url}
          alt="header"
          height={300}
          style={{
            objectFit: 'cover',
            maxWidth: '100%',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)'
          }}
        />
      ) : null}

      {!url && !isUploading ? (
        <Box
          sx={{
            height: '300px',
            width: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0,0.2)'
          }}>
          <Typography>No image</Typography>
        </Box>
      ) : null}

      {isUploading ? (
        <Box sx={{ height: '300px', width: '400px' }}>
          <UploadingImage progress={progress} />
        </Box>
      ) : null}

      <EditImageModal
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        name={alt || 'cover_url_alt'}
      />

      <Box
        sx={{
          position: 'absolute',
          right: '10px',
          bottom: '10px',
          display: 'flex',
          gap: '10px'
        }}>
        {url ? (
          <Box onClick={() => setIsEditOpen(true)}>
            <ImageButtonAction Icon={Edit} />
          </Box>
        ) : null}
        <label htmlFor="image">
          <input
            id="image"
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleReplacement}
          />
          <ImageButtonAction Icon={ChangeCircle} />
        </label>
      </Box>
    </Box>
  );
}

export default () => {};
