import '@mdxeditor/editor/style.css';

import {
  BoldItalicUnderlineToggles,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  listsPlugin,
  toolbarPlugin,
  BlockTypeSelect,
  ListsToggle,
  Separator,
  CreateLink,
  markdownShortcutPlugin,
  linkPlugin,
  thematicBreakPlugin,
  imagePlugin,
  InsertImage,
  linkDialogPlugin,
  quotePlugin
} from '@mdxeditor/editor';
import { Box } from '@mui/joy';

import { useUploadImage } from '../ImageUploader/ImageUploader';

function MarkdownTools({ allowImages }) {
  return (
    <>
      <UndoRedo />
      <Separator />
      <BoldItalicUnderlineToggles />
      <Separator />
      <BlockTypeSelect />
      <Separator />
      <CreateLink />
      {allowImages && <InsertImage />}
      <Separator />
      <ListsToggle />
    </>
  );
}

export default function Markdown({
  defaultContent,
  handleOnChange,
  imagesRepository,
  allowImages = false
}) {
  const { saveFile } = useUploadImage();
  return (
    <Box sx={{ border: '1px solid #dedede', borderRadius: '4px' }} className="markdown">
      <MDXEditor
        id="blog-posts"
        markdown={defaultContent.replace(/<iframe.*?src=["'](.*?)["'].*?<\/iframe>/g, '$1')}
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          markdownShortcutPlugin(),
          linkPlugin(),
          linkDialogPlugin(),
          thematicBreakPlugin(),
          quotePlugin(),
          allowImages &&
            imagePlugin({
              imageUploadHandler: async (file) => {
                return saveFile(file, imagesRepository);
              }
            }),
          toolbarPlugin({
            toolbarContents: () => MarkdownTools({ allowImages })
          })
        ].filter(Boolean)}
        onChange={(markdown) => handleOnChange(markdown.replace(/<!---->/g, ''))}
      />
    </Box>
  );
}
