import { Input } from '@esui/lib.formik.input';
import { Textarea } from '@esui/lib.formik.textarea';
import { Box } from '@mui/joy';
import { useParams } from 'react-router-dom';

import { repathImg, REPOSITORIES } from '../../lib/upload';

import { ImageReplacer } from '../ImageUploader/ImageUploader';

export default function HeaderInformation({ headerSrc, setFieldValue }) {
  const { id } = useParams();
  return (
    <>
      <ImageReplacer
        repository={REPOSITORIES.LANDING_PAGE}
        url={repathImg(headerSrc, REPOSITORIES.LANDING_PAGE, { _id: id })}
        handleMutation={([url]) => setFieldValue('header', url)}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          background: '#fff',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
        }}>
        <Box>
          <Input name="title" label="Title" />
        </Box>
        <Box>
          {' '}
          <Input name="subtitle" label="Subtitle" />
        </Box>
        <Textarea name="header_text" label="Header Text" />
      </Box>
    </>
  );
}
