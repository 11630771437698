import { Routes, Route } from 'react-router-dom';
import { useIsFetching } from '@tanstack/react-query';
import { Box } from '@mui/joy';

import OptionsTrip from './pages/Trip/OptionsTrip';
import ListNextDepartures from './pages/NextDepartures/ListNextDepartures';
import EditNextDepartures from './pages/NextDepartures/EditNextDepartures';
import Page401 from './pages/Errors/401';
import Page404 from './pages/Errors/404';
import Page500 from './pages/Errors/500';
import Home from './pages/Home/Home';

import PendingApproval from './pages/PendingApproval';
import PendingTrips from './pages/PendingApproval/PendingTrips';
import FAQs from './pages/PendingApproval/Faqs';
import RecentlyPublished from './pages/RecentlyPublished/RecentlyPublished';
import EditAllFAQ from './pages/FAQ/EditAllFAQ';
import Price from './pages/Price/Price';
import UserProvider from './contexts/userContext';
import EditGuide from './pages/Guide/EditGuide';
import PriceCalculator from './pages/PriceCalculator/PriceCalculator';
import ListValues from './pages/ValuesEditor/ListValues';
import EditValues from './pages/ValuesEditor/EditValues';
import EditorGuide from './pages/GuideEditor/EditGuide';
import BookingHealth from './pages/Booking/BookingHealth';
import BookingHealthByCode from './pages/Booking/BookingHealthByCode';
import UpdateBooking from './pages/Booking/UpdateBooking';
import PriceReCalculator from './pages/PriceReCalculator';
import GuideBlocks from './pages/Guide/GuideBlocks';
import TestPriceReCalculator from './pages/PriceReCalculator/Test';
import BlocksByDates from './pages/Availability/BlocksByDates';
import RunSyncs from './pages/RunSyncs/RunSyncs';
import GeoLocation from './pages/GeoLocation/GeoLocation';
import LandingPages from './pages/LandingPages/LandingPages';
import LandingPageInfo from './pages/LandingPages/LandingPageInfo';
import AIAssistant from './pages/AIAssistant';
import Content from './pages/Trip/Content/Content';
import Seo from './pages/Trip/Seo';
import PriceDetails from './pages/Trip/PriceDetails';
import GeneralInfo from './pages/Trip/GeneralInfo/GeneralInfo';
import Guide from './pages/Trip/Guide';
import Trips from './pages/Trips/Trips';
import NewTrip from './pages/Trip/NewTrip';
import Guides from './pages/Guides';
import GuideReviews from './pages/Guide/GuideReviews';
import TripReviews from './pages/Trips/TripReviews';
import GuideContent from './pages/Guide/GuideContent';
import GuideGeneralInfo from './pages/Guide/GuideGeneralInfo';
import GuideSeo from './pages/Guide/GuideSeo';
import NewGuide from './pages/Guide/NewGuide';
import BlogPosts from './pages/BlogPosts/BlogPosts';
import BlogPostOptions from './pages/BlogPosts/BlogPostOptions';
import BlogPostSEO from './pages/BlogPosts/Seo';
import BlogPostGeneralInfo from './pages/BlogPosts/BlogPostGeneralInfo';
import BlogPostContent from './pages/BlogPosts/BlogPostContent';
import BlogPostContentPreview from './pages/BlogPosts/BlogPostContentPreview';
import StaticPages from './pages/StaticPages/StaticPages';
import Funnel from './pages/Stats/Funnel';
import Bookings from './pages/Stats/Bookings';
import RedirectList from './pages/Redirects/RedirectList';
import RedirectForm from './pages/Redirects/RedirectForm';
import BlogPostNew from './pages/BlogPosts/BlogPostNew';
import Activities from './pages/Activities/Activities';
import Activity from './pages/Activities/Activity';
import Mails from './pages/ES-Messaging/Mails';
import EditMail from './pages/ES-Messaging/EditMail';
import ListMailsReusableComponents from './pages/MailsReusableComponents/ListMailsReusableComponents';
import EditMailsReusableComponent from './pages/MailsReusableComponents/EditMailsReusableComponent';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import NewPassword from './pages/Login/NewPassword';
import ListGlossaries from './pages/Glossaries/ListGlossaries';
import EditGlossary from './pages/Glossaries/EditGlossary';
import Monitor from './pages/Monitor/Monitor';
import Terms from './pages/StaticPages/Terms';
import Faq from './pages/StaticPages/Faq';
import TripDumpInfo from './pages/Trips/TripDumpInfo';
import EditHotels from './pages/Hotels/EditHotels';
import SiteMapDebuger from './pages/SiteMap/SiteMapDebuger';
import OptionsAdmin from './pages/Admin/Options';
import Reviews from './pages/Reviews/Reviews';

const App = () => {
  const isFetching = useIsFetching();
  return (
    <>
      {isFetching ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            width: '100vw',
            height: '3px',
            backgroundColor: 'var(--joy-palette-primary-plainColor)',
            zIndex: 'var(--zIndex-3)'
          }}
        />
      ) : (
        ''
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/new-password" element={<NewPassword />} />

        <Route element={<UserProvider />}>
          <Route path="/" element={<Home />} />
          <Route path="/recentlyPublished" element={<RecentlyPublished />} />
          <Route path="/pendingApproval" element={<PendingApproval />} />
          <Route path="/pendingApproval/:tripId" element={<PendingTrips />} />
          <Route path="/pendingApproval/:tripId/faqs" element={<FAQs />} />

          <Route path="/blog-posts" element={<BlogPosts />} />
          <Route path="/blog-post/new" element={<BlogPostNew />} />
          <Route path="/blog-post/:blogPostId/options" element={<BlogPostOptions />} />
          <Route path="/blog-post/:blogPostId/seo" element={<BlogPostSEO />} />
          <Route path="/blog-post/:blogPostId/general-info" element={<BlogPostGeneralInfo />} />
          <Route path="/blog-post/:blogPostId/content" element={<BlogPostContent />} />
          <Route
            path="/blog-post/:blogPostId/content/preview"
            element={<BlogPostContentPreview />}
          />

          <Route path="/trips" element={<Trips />} />
          <Route path="/trip/new" element={<NewTrip />} />
          <Route path="/trip/:tripId/options" element={<OptionsTrip />} />
          <Route path="/trip/:tripId/content" element={<Content />} />
          <Route path="/trip/:tripId/guide" element={<Guide />} />
          <Route path="/trip/:tripId/seo" element={<Seo />} />
          <Route path="/trip/:tripId/price-details" element={<PriceDetails />} />
          <Route path="/trip/:tripId/reviews" element={<TripReviews />} />
          <Route path="/trip/:tripId/general-info" element={<GeneralInfo />} />
          <Route path="/trip/:tripId/FAQ/all" element={<EditAllFAQ />} />
          <Route path="/trip/:tripId/hotels" element={<EditHotels />} />
          <Route path="/trip/:tripId/openGroup" element={<ListNextDepartures />} />
          <Route path="/trip/:tripId/openGroup/:ndId" element={<EditNextDepartures />} />
          <Route path="/trip/:tripId/price" element={<Price />} />
          <Route path="/trip/:tripId/geolocation" element={<GeoLocation />} />
          <Route path="/trip/:tripId/ai-assistant" element={<AIAssistant />} />
          <Route path="/trip/:tripId/dumpInfo" element={<TripDumpInfo />} />

          <Route path="/priceCalculator" element={<PriceCalculator />} />
          <Route path="/values" element={<ListValues />} />
          <Route path="/values/:id" element={<EditValues />} />
          <Route path="/redirect" element={<RedirectList />} />
          <Route path="/redirect/:id" element={<RedirectForm />} />

          <Route path="/guides" element={<Guides />} />
          <Route path="/guide/new" element={<NewGuide />} />
          <Route path="/guide/:guideId" element={<EditGuide />} />
          <Route path="/guide/:guideId/settings" element={<EditorGuide />} />
          <Route path="/guide/:guideId/general-info" element={<GuideGeneralInfo />} />
          <Route path="/guide/:guideId/content" element={<GuideContent />} />
          <Route path="/guide/:guideId/reviews" element={<GuideReviews />} />
          <Route path="/guide/:guideId/blocks" element={<GuideBlocks />} />
          <Route path="/guide/:guideId/seo" element={<GuideSeo />} />

          <Route path="/booking/:id" element={<BookingHealth />} />
          <Route path="/booking/:id/update" element={<UpdateBooking />} />
          <Route path="/booking/:id/price-recalculator" element={<PriceReCalculator />} />
          <Route path="/booking/test-price-recalculator" element={<TestPriceReCalculator />} />
          <Route path="/booking" element={<BookingHealthByCode />} />

          <Route path="/landing-pages" element={<LandingPages />} />
          <Route path="/landing-pages/:id" element={<LandingPageInfo />} />

          <Route path="/static-pages" element={<StaticPages />} />
          <Route path="/static-pages/:id/terms" element={<Terms />} />
          <Route path="/static-pages/:id/faq" element={<Faq />} />

          <Route path="/glossaries" element={<ListGlossaries />} />
          <Route path="/glossaries/:id" element={<EditGlossary />} />

          <Route path="/activities" element={<Activities />} />
          <Route path="/activities/:id" element={<Activity />} />

          <Route path="/funnel" element={<Funnel />} />
          <Route path="/funnel/bookings" element={<Bookings />} />

          <Route index path="/availability/blocks" element={<BlocksByDates />} />

          <Route index path="/reviews" element={<Reviews />} />

          <Route index path="/admin" element={<OptionsAdmin />} />
          <Route index path="/admin/runsync" element={<RunSyncs />} />
          <Route index path="/admin/sitemapdebuger" element={<SiteMapDebuger />} />
          <Route index path="/admin/monitor" element={<Monitor />} />

          <Route index path="/messaging/mails" element={<Mails />} />
          <Route index path="/messaging/mails/:id" element={<EditMail />} />
          <Route
            index
            path="/messaging/mails-reusable-components"
            element={<ListMailsReusableComponents />}
          />
          <Route
            index
            path="/messaging/mails-reusable-components/:id"
            element={<EditMailsReusableComponent />}
          />
        </Route>

        <Route path="/login" element={<Page401 />} />

        <Route path="/error/401" element={<Page401 />} />
        <Route path="/error/404" element={<Page404 />} />
        <Route path="/error/500" element={<Page500 />} />
      </Routes>
    </>
  );
};

export default App;
