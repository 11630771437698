import { Box, FormLabel, Checkbox as JoyCheckbox, Option, Select } from '@mui/joy';
import { Checkbox } from '@esui/lib.formik.checkbox';
import { Input } from '@esui/lib.formik.input';
import toast from 'react-hot-toast';

import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import ExpandableContent from '../Trip/ExpandableContent';
import { ALL_CERTIFICATES, GUIDE_TYPES, LANGUAGES, VERIFIED_INFO } from '../../constants/guide';
import ArrayField from '../../components/ArrayField';
import { getAllCountries } from '../../lib/taxonomies';
import { patchGuide } from '../../lib/guide';

import { useLocale } from '../../hooks/useLocale';

import GuideEditLayout from './GuideEditLayout';

export default function GuideGeneralInfo() {
  const [locale] = useLocale();

  if (!locale) return null;

  const handleMutation = async (guideId, newGuide) => {
    const newData = {
      agency: newGuide.agency,
      guide_type: newGuide.guide_type,
      enabled: newGuide.enabled,
      fee_category: newGuide.fee_category || 'B',
      guided_countries: newGuide.guided_countries.map((c) => c._id),
      certificates: newGuide.certificates,
      verified_info: newGuide.verified_info,
      languages: newGuide.languages
    };

    if (newGuide.custom_comission) {
      newData.custom_comission = newGuide.custom_comission;
    }

    await toast.promise(patchGuide(guideId, newData), {
      loading: 'Saving changes...',
      success: 'Changes saved successfully',
      error: 'Error while saving changes'
    });
  };

  return (
    <GuideEditLayout title="General info" handleMutation={handleMutation}>
      {(values, setFieldValue) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <LocaleSelector />
          <ExpandableContent title="General info">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              <Box>
                <JoyCheckbox
                  label="Agency"
                  defaultChecked={values.agency}
                  onChange={(e) => setFieldValue('agency', e.target.checked)}
                />
              </Box>
              <Box>
                <FormLabel>Status</FormLabel>
                <Select
                  value={values.enabled ? 'enabled' : 'disabled'}
                  onChange={(e, value) => {
                    return value === 'enabled'
                      ? setFieldValue('enabled', true)
                      : setFieldValue('enabled', false);
                  }}>
                  <Option value="enabled">Enabled</Option>
                  <Option value="disabled">Disabled</Option>
                </Select>
              </Box>
              <Box>
                <FormLabel>Fee category</FormLabel>
                <Select
                  value={values.fee_category}
                  onChange={(e, v) => setFieldValue('fee_category', v)}>
                  <Option value="A">A (20%)</Option>
                  <Option value="B">B (15%)</Option>
                  <Option value="C">C (10%)</Option>
                  <Option value="D">D (5%)</Option>
                </Select>
              </Box>
              <Box>
                <FormLabel>Custom comission</FormLabel>
                <Input type="number" name="custom_comission" value={values.custom_comission} />
              </Box>
            </Box>
          </ExpandableContent>

          <ExpandableContent title="Guide type">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Checkbox
                items={GUIDE_TYPES.map((item) => ({
                  value: item,
                  label: item,
                  checked: values.guide_type?.includes(item)
                }))}
                name="guide_type"
              />
            </Box>
          </ExpandableContent>

          <ExpandableContent title="Guided countries">
            <ArrayField
              valueKey="guided_countries"
              currentValues={values.guided_countries}
              name="guided_countries"
              fn={getAllCountries}
              locale={locale}
            />
          </ExpandableContent>

          <ExpandableContent title="Certificates">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Checkbox
                items={ALL_CERTIFICATES.map((certificate) => ({
                  label: `${certificate.title} (${certificate.certificate})`,
                  value: certificate.certificate,
                  checked: values.certificates.includes(certificate.certificate)
                }))}
                name="certificates"
              />
            </Box>
          </ExpandableContent>

          <ExpandableContent title="Verified info">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Checkbox
                items={VERIFIED_INFO.map((item) => ({
                  ...item,
                  checked: values.verified_info.includes(item.value)
                }))}
                name="verified_info"
              />
            </Box>
          </ExpandableContent>

          <ExpandableContent title="Languages">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Checkbox
                items={LANGUAGES.map((item) => ({
                  value: item,
                  label: item,
                  checked: values.languages.includes(item)
                }))}
                name="languages"
              />
            </Box>
          </ExpandableContent>
        </Box>
      )}
    </GuideEditLayout>
  );
}
