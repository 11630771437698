import { Add } from '@mui/icons-material';
import { Box, IconButton } from '@mui/joy';

import { UploadingImage, useUploadImage } from '../../../components/ImageUploader/ImageUploader';

export default function NewPicture({ handleNewPicture, repository }) {
  const { uploadImage, progress, isUploading, uploadingFilesAmount } = useUploadImage();

  const handleUploadImage = async (e) => {
    const urls = await uploadImage(e, repository);
    console.log(urls);
    handleNewPicture(urls);
  };

  const uploadingFilesAmountArr = new Array(uploadingFilesAmount).fill(0);

  return isUploading ? (
    uploadingFilesAmountArr.map((_, index) => <UploadingImage key={index} progress={progress} />)
  ) : (
    <Box
      sx={{
        border: '1px solid #eee',
        display: 'grid',
        placeContent: 'center',
        height: '300px',
        position: 'relative'
      }}>
      <IconButton variant="solid" color="primary">
        <label htmlFor="picture" style={{ cursor: 'pointer' }}>
          <input
            multiple
            id="picture"
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleUploadImage}
          />
          <Add />
        </label>
      </IconButton>
    </Box>
  );
}
