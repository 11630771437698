import FAQs from '../../pages/PendingApproval/Faqs';
import NextDepartures from '../../pages/PendingApproval/NextDepartures';
import Pricing from '../../pages/PendingApproval/Pricing';
import WhatsIncluded from '../../pages/PendingApproval/WhatsIncluded';
import PriceIncludes from '../../pages/PendingApproval/PriceIncludes';
import DefaultMessage, { ThankYoumessage } from '../../pages/PendingApproval/DefaultMessage';

const mapDataToApprove = (data, field) => {
  const information = {
    faqs: {
      Component: FAQs,
      data: (() => {
        if (!field.includes('faqs')) return null;
        return data.faqs.map(({ question, answer, _id }) => ({
          question,
          answer,
          _id
        }));
      })()
    },
    pricing: {
      Component: Pricing,
      data: (() => {
        if (field !== 'pricing') return null;
        return Object.keys(data)
          .map((k) => {
            if (!Number.isNaN(Number(k))) return { people: k, price: data[k] };
            if (k === 'currency') return { currency: data[k] };
            return null;
          })
          .filter(Boolean);
      })()
    },
    next_departures: {
      Component: NextDepartures,
      data
    },
    whats_included: {
      Component: WhatsIncluded,
      data
    },
    price_includes: {
      Component: PriceIncludes,
      data
    },
    defaultMessage: {
      Component: DefaultMessage,
      data
    },
    thanksMessage: {
      Component: ThankYoumessage,
      data
    }
  };
  if (field.includes('.')) {
    return information[field.split('.').at(-1)];
  }
  return information[field];
};

export default mapDataToApprove;
