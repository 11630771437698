import { Box, Button, FormLabel } from '@mui/joy';
import { Textarea } from '@esui/lib.formik.textarea';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import { ImageReplacer } from '../../components/ImageUploader/ImageUploader';
import TranslatedFieldLabel from '../../components/TranslatedFieldLabel/TranslatedFieldLabel';
import Markdown from '../../components/Markdown/Markdown';
import { patchBlogPost } from '../../lib/blog-posts';

import { useLocale } from '../../hooks/useLocale';

import { REPOSITORIES } from '../../lib/upload';

import BlogPostEditLayout from './BlogPostEditLayout';

const addIframesToMarkdown = (markdown) => {
  const updatedMarkdown = markdown
    .replace(/<iframe.*?src=["'](.*?)["'].*?<\/iframe>/g, '$1')
    .replace(
      /<?(https:\/\/www\.google\.com\/maps\/d\/u\/\d\/embed([^\s>]+))/g,
      '<iframe src="$1"></iframe>'
    )
    .replace(/<?(https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+)>?/g, '<iframe src="$1"></iframe>')
    .replace(/<?(https:\/\/www\.google\.com\/maps\/embed\?[^\s]*)>?/g, '<iframe src="$1"></iframe>')
    .replaceAll('</iframe>>', '</iframe>');

  return updatedMarkdown
    .replace(/<?(https:\/\/www\.youtube\.com\/embed\/[^)]([^\s>]+))/g, '<iframe src="$1"></iframe>')
    .replaceAll('</iframe>>', '</iframe>');
};

export default function BlogPostContent() {
  const [locale] = useLocale();

  const handleMutation = async (postId, newPost) => {
    const newData = {
      featured_image: {
        url: newPost.featured_image.url,
        alt: newPost.featured_image.alt
      },
      i18n: {
        ...newPost.i18n,
        [locale]: {
          ...newPost.i18n[locale],
          content: addIframesToMarkdown(newPost.i18n[locale].content)
        }
      }
    };
    await toast.promise(
      patchBlogPost({
        _id: postId,
        ...newData
      }),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  return (
    <BlogPostEditLayout title="Content" handleMutation={handleMutation}>
      {({ featured_image, i18n, ...rest }, setFieldValue) => {
        const { content } = i18n[locale] || {};
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <LocaleSelector />
            <Box>
              <FormLabel>Header</FormLabel>
              <ImageReplacer
                repository={REPOSITORIES.STATIC_PAGE_HEADER}
                alt={featured_image.alt}
                url={featured_image.url}
                handleMutation={([url]) => setFieldValue('featured_image.url', url)}
              />
            </Box>
            <Textarea name={`i18n.${locale}.title`} label="Title" />
            <Textarea name={`i18n.${locale}.excerpt`} label="Excerpt" />
            <Box>
              <FormLabel>
                <TranslatedFieldLabel label="Content" />
              </FormLabel>
              <Link
                state={{
                  content: addIframesToMarkdown(content),
                  header: featured_image.url,
                  ...rest
                }}
                to={`${window.location.pathname}/preview`}>
                <Button variant="outlined" sx={{ margin: '10px 0px' }}>
                  Preview content
                </Button>
              </Link>
              <Markdown
                allowImages
                imagesRepository={REPOSITORIES.STATIC_PAGE}
                defaultContent={content || ''}
                handleOnChange={(markdown) => setFieldValue(`i18n.${locale}.content`, markdown)}
              />
            </Box>
          </Box>
        );
      }}
    </BlogPostEditLayout>
  );
}
